:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-800: #595959;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-tenunits: 80px;
  --dl-space-space-twounits: 32px;
  --dl-color-primary1-blue60: #e2ddf5ff;
  --dl-color-primary1-blue80: #5228f5ff;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-color-primary1-blue100: #291477ff;
  --dl-space-space-doubleunit: 16px;
  --dl-space-space-threeunits: 48px;
  --dl-color-secondary-gray500: #d9d9d9ff;
  --dl-color-secondary-grey400: #eff0f2ff;
  --dl-space-space-oneandhalfunits: 24px;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.heading2 {
  font-size: 2.5rem;
}
.heading3 {
  font-size: 1.8rem;
}
.sectionTitle {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1.2;
  text-transform: uppercase;
}
.heading1 {
  font-size: 4.5rem;
  font-weight: 700;
}
.heading4 {
  font-size: 1.2rem;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Section-Heading {
  font-size: 48px;
  font-family: Overpass;
  font-weight: 900;
  line-height: 1.22;
  text-transform: none;
  text-decoration: none;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Section-Text {
  font-size: 18px;
  font-family: Playfair Display;
  font-weight: 600;
  line-height: 1.55;
  letter-spacing: 2;
  text-transform: none;
  text-decoration: none;
}
@media(max-width: 767px) {
  .heading2 {
    font-size: 1.8rem;
  }
  .heading3 {
    font-size: 1.1rem;
  }
  .heading1 {
    font-size: 2.5rem;
  }
  .heading4 {
    font-size: 1.2rem;
  }
}
