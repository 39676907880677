.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  background-color: var(--dl-color-primary1-blue80);
}
.home-navbar-interactive {
  width: 90%;
  display: flex;
  position: sticky;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.home-logo {
  font-size: 2em;
  font-family: "Lato";
  font-weight: bold;
  text-transform: uppercase;
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-nav1 {
  transition: 0.3s;
  text-decoration: none;
}
.home-nav1:hover {
  color: #3d6e70ff;
}
.home-nav2 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav2:hover {
  color: #3d6e70ff;
}
.home-nav3 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav3:hover {
  color: #3d6e70ff;
}
.home-nav4 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav4:hover {
  color: #3d6e70ff;
}
.home-nav5 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav5:hover {
  color: #3d6e70ff;
}
.home-nav51 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav51:hover {
  color: #3d6e70ff;
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo1 {
  font-size: 2em;
  font-weight: bold;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  fill: var(--dl-color-gray-900);
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-nav11 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav21 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav31 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav41 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav52 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.home-buttons {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-login {
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: 1.5rem;
  border-radius: 30px;
  padding-right: 1.5rem;
}
.home-register {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  padding-left: 1.5rem;
  border-radius: 30px;
  padding-right: 1.5rem;
  background-color: var(--dl-color-primary1-blue100);
}
.home-register:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-primary1-blue80);
}
.home-hero1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  min-height: auto;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container01 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero-heading {
  color: var(--dl-color-gray-white);
  max-width: 800px;
  text-align: center;
  font-family: "Raleway";
  line-height: 1.6;
}
.home-details-image {
  width: 400px;
  height: 400px;
  align-self: center;
  object-fit: cover;
  margin-left: 0px;
  border-radius: var(--dl-radius-radius-round);
}
.home-hero-sub-heading {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  text-align: center;
  line-height: 1.6;
}
.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-hero-button1 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  transition: 0.3s;
  font-weight: bold;
  padding-top: 1.5rem;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 0px;
  padding-left: 3rem;
  border-radius: 45px;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  text-decoration: none;
  background-color: var(--dl-color-primary1-blue100);
}
.home-hero-button1:hover {
  color: var(--dl-color-gray-white);
  border-color: rgba(41, 20, 119, 0.9);
  background-color: rgba(41, 20, 119, 0.9);
}
.home-details {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-details1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.home-container02 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text002 {
  color: var(--dl-color-primary1-blue80);
  text-align: left;
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-details-heading {
  width: 80%;
  text-align: left;
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-details-sub-heading {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: left;
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-details-image1 {
  width: 400px;
  height: 400px;
  object-fit: cover;
  margin-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-round);
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-features-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-secondary-grey400);
}
.home-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 2735px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.home-container03 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text005 {
  color: var(--dl-color-primary1-blue80);
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-container04 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.home-card-experience {
  width: 516px;
  height: 100%;
  display: flex;
  align-self: flex-start;
  margin-top: 5%;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: flex-start;
}
.home-card-experience-title {
  align-self: flex-start;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-card-ops-support {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-style: dashed;
  border-width: 1px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-image {
  width: 29px;
  height: 34px;
  object-fit: cover;
  padding-right: 1%;
}
.home-container05 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text008 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text009 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text012 {
  padding: 4px;
  font-size: 14px;
  font-weight: 600;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 6px;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-right-radius: var(--dl-radius-radius-radius8);
}
.home-card-ops-support1 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-style: dashed;
  border-width: 1px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-image1 {
  width: 10%;
  height: 10%;
  object-fit: cover;
}
.home-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text025 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text026 {
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
}
.home-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-text027 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text028 {
  padding: 4px;
  font-size: 14px;
  font-weight: 600;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 6px;
  margin-bottom: var(--dl-space-space-unit);
  padding-right: 4px;
  text-transform: capitalize;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-right-radius: var(--dl-radius-radius-radius8);
}
.home-card-intern {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-style: dashed;
  border-width: 1px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-image2 {
  width: 8%;
  height: 8%;
  object-fit: cover;
  margin-right: 2%;
}
.home-container08 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text042 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text043 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text046 {
  padding: 4px;
  font-size: 14px;
  font-weight: 600;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 6px;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-right-radius: var(--dl-radius-radius-radius8);
}
.home-card-intern1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-style: dashed;
  border-width: 1px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-image3 {
  width: 8%;
  height: 8%;
  object-fit: cover;
  margin-right: 2%;
}
.home-container09 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text062 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text063 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text064 {
  padding: 4px;
  font-size: 14px;
  font-weight: 600;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 6px;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-right-radius: var(--dl-radius-radius-radius8);
}
.home-card-intern2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-style: dashed;
  border-width: 1px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-image4 {
  width: 8%;
  height: 8%;
  object-fit: cover;
  margin-right: 2%;
}
.home-container10 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text077 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text078 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text079 {
  padding: 4px;
  font-size: 14px;
  font-weight: 600;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 6px;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-right-radius: var(--dl-radius-radius-radius8);
}
.home-card-experience1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  margin-top: 5%;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: flex-start;
}
.home-card-experience-title1 {
  align-self: flex-start;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-card-ops-support2 {
  width: 100%;
  height: 302px;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-style: dashed;
  border-width: 1px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-image5 {
  width: 10%;
  height: 10%;
  object-fit: cover;
  padding-right: 1%;
}
.home-container11 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text093 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text094 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text095 {
  padding: 4px;
  font-size: 14px;
  font-weight: 600;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 6px;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-right-radius: var(--dl-radius-radius-radius8);
}
.home-card-ops-support3 {
  width: 100%;
  height: 259px;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-style: dashed;
  border-width: 1px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-image6 {
  width: 32px;
  height: 35px;
  object-fit: cover;
}
.home-container12 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text099 {
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text100 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text101 {
  padding: 4px;
  font-size: 14px;
  font-weight: 600;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 6px;
  margin-bottom: var(--dl-space-space-unit);
  padding-right: 4px;
  text-transform: capitalize;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-right-radius: var(--dl-radius-radius-radius8);
}
.home-blog {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-text107 {
  color: var(--dl-color-primary1-blue80);
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-navlink {
  display: contents;
}
.home-component {
  text-decoration: none;
}
.home-navlink1 {
  display: contents;
}
.home-component1 {
  text-decoration: none;
}
.home-testimonial {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-text110 {
  color: var(--dl-color-gray-black);
  font-size: 3rem;
}
.home-text111 {
  color: var(--dl-color-gray-black);
  font-size: 1.15rem;
  max-width: 600px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container13 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-size: cover;
  justify-content: space-between;
  background-image: url('https://images.unsplash.com/photo-1485628390555-1a7bd503f9fe?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE3fHxhcmNoaXRlY3R1cmUlMjBtaW5pbWFsfGVufDB8fHx8MTYyNjE4NDA0Mg&ixlib=rb-1.2.1&w=1500');
  background-position: center;
}
.home-text112 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text113 {
  max-width: var(--dl-size-size-maxwidth);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-faq-container {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.home-container15 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-logo2 {
  color: var(--dl-color-gray-white);
  font-size: 2em;
  font-weight: bold;
}
.home-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.home-nav12 {
  color: var(--dl-color-secondary-gray500);
  margin-left: 0px;
  text-decoration: none;
}
.home-nav22 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav32 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav42 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav53 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav54 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-800);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text123 {
  color: var(--dl-color-secondary-gray500);
}
.home-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-link {
  display: contents;
}
.home-icon10 {
  fill: var(--dl-color-secondary-gray500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link1 {
  display: contents;
}
.home-icon12 {
  fill: var(--dl-color-secondary-gray500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link2 {
  display: contents;
}
.home-icon14 {
  fill: var(--dl-color-secondary-gray500);
  width: 24px;
  height: 24px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .home-hero1 {
    flex-direction: column;
  }
  .home-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-hero-heading {
    text-align: center;
  }
  .home-text {
    text-align: center;
  }
  .home-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-details-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-card-experience-title {
    text-align: center;
  }
  .home-card-experience-title1 {
    text-align: center;
  }
  .home-text110 {
    text-align: center;
  }
  .home-text111 {
    text-align: center;
  }
  .home-container13 {
    flex-direction: column;
  }
  .home-text113 {
    width: 100%;
  }
  .home-text114 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-nav11 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav21 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav31 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav41 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav52 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-details-image {
    margin-top: var(--dl-space-space-threeunits);
    margin-left: 0px;
  }
  .home-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-details1 {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .home-container02 {
    align-items: center;
    justify-content: flex-start;
  }
  .home-text002 {
    text-align: center;
  }
  .home-details-heading {
    text-align: center;
  }
  .home-details-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-details-image1 {
    margin-top: var(--dl-space-space-threeunits);
    margin-left: 0px;
  }
  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container04 {
    grid-template-columns: 1fr;
  }
  .home-card-ops-support {
    flex-direction: row;
  }
  .home-container05 {
    flex-direction: column;
  }
  .home-card-ops-support1 {
    flex-direction: row;
  }
  .home-container06 {
    flex-direction: column;
  }
  .home-card-intern {
    flex-direction: row;
  }
  .home-container08 {
    flex-direction: column;
  }
  .home-card-intern1 {
    flex-direction: row;
  }
  .home-container09 {
    flex-direction: column;
  }
  .home-card-intern2 {
    flex-direction: row;
  }
  .home-container10 {
    flex-direction: column;
  }
  .home-card-ops-support2 {
    flex-direction: row;
  }
  .home-container11 {
    flex-direction: column;
  }
  .home-card-ops-support3 {
    flex-direction: row;
  }
  .home-container12 {
    flex-direction: column;
  }
  .home-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-testimonial {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text113 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text114 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container16 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text123 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .home-navbar-interactive {
    width: 90%;
    padding: var(--dl-space-space-unit);
  }
  .home-logo {
    font-size: 1.5em;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-logo1 {
    font-size: 1.5em;
  }
  .home-icon02 {
    fill: var(--dl-color-gray-900);
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container05 {
    flex-direction: column;
  }
  .home-text008 {
    align-self: flex-start;
  }
  .home-container06 {
    flex-direction: column;
  }
  .home-text025 {
    align-self: flex-start;
  }
  .home-text026 {
    align-self: flex-start;
  }
  .home-container08 {
    flex-direction: column;
  }
  .home-container09 {
    flex-direction: column;
  }
  .home-container10 {
    flex-direction: column;
  }
  .home-container11 {
    flex-direction: column;
  }
  .home-text093 {
    align-self: flex-start;
  }
  .home-container12 {
    flex-direction: column;
  }
  .home-text099 {
    align-self: flex-start;
  }
  .home-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-testimonial {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-container14 {
    align-items: center;
    flex-direction: column;
  }
  .home-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container16 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text123 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
